.rst__rowLabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
}

.rst__rowWrapper {
    padding: 2px 2px 2px 0;
}

.rst__lineFullVertical:after,
.rst__lineHalfHorizontalRight:before,
.rst__lineHalfVerticalBottom:after,
.rst__lineHalfVerticalTop:after {
    background-color: #fdd835;
}

.rst__rowContents {
    background-color: #424242;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border: none;
}
